// @ts-check
import classNames from 'classnames';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import scrollToTop from '@/page/utils/scrollToTop';

const propTypes = {
  className: PropTypes.string,
  total: PropTypes.number.isRequired,
  current_page: PropTypes.number.isRequired,
  total_pages: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  isScrollTopOnPageChange: PropTypes.bool,
  onPageChanged: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['default', 'datatable']),
};

const defaultProps = {
  className: '',
  isScrollTopOnPageChange: true,
  variant: 'default',
};

/**
 * @param {object} props
 * @param {string} props.className
 * @param {number} props.total
 * @param {number} props.current_page
 * @param {number} props.total_pages
 * @param {number} props.per_page
 * @param {boolean} props.isScrollTopOnPageChange
 * @param {Function} props.onPageChanged
 * @param {string} props.variant
 * @returns {React.ReactNode}
 */
function Pager({
  total_pages,
  total,
  per_page,
  current_page,
  isScrollTopOnPageChange,
  onPageChanged,
  className,
  variant,
}) {
  // Variables
  const prevDisabled = current_page === 1;
  const nextDisabled = current_page === total_pages || total_pages === 0;
  const startCount = per_page * (current_page - 1) + 1;
  const endCount = current_page === total_pages ? total : current_page * per_page;

  // Effects
  useEffect(() => {
    if (isScrollTopOnPageChange) {
      scrollToTop();
      document
        .querySelector('.vertical-scroll-container--datatable')
        ?.scroll({ left: 0, behavior: 'smooth' });
    }
  }, [current_page]);

  // Functions
  /**
   * @param {number} page
   */
  function gotoPage(page) {
    onPageChanged(page);
  }

  function handleOnPrevious() {
    if (!prevDisabled) {
      gotoPage(current_page - 1);
    }
  }

  function handleOnNext() {
    if (!nextDisabled) {
      gotoPage(current_page + 1);
    }
  }

  // Render
  return (
    <div className={`pager pager--${variant} small ${className}`}>
      <ul className="list-inline list-unstyled">
        <li className={classNames({ disabled: prevDisabled })}>
          <button className="btn btn-chromeless" onClick={handleOnPrevious} type="button">
            Prev
          </button>
        </li>
        <li>
          <span>{`${startCount}-${endCount} of ${total}`}</span>
        </li>
        <li className={classNames({ disabled: nextDisabled })}>
          <button className="btn btn-chromeless" onClick={handleOnNext} type="button">
            Next
          </button>
        </li>
      </ul>
    </div>
  );
}

Pager.propTypes = propTypes;
Pager.defaultProps = defaultProps;

export default Pager;
