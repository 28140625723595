import { useContext } from 'react';
import PredicatesContext from '@/predicates/context/Predicates';

/**
 * @typedef {import('../typeDefs').Predicate} Predicate
 */

/**
 * @callback addPredicate
 * @param {Predicate} predicate
 */

/**
 * @callback removePredicate
 * @param {string} predicateId
 */

/**
 * @callback updatePredicate
 * @param {Predicate} predicate
 */

/**
 * @returns {{
 * predicates: Predicate[],
 * setPredicates: Function,
 * predefinedPredicates: Predicate[],
 * addPredicate: addPredicate,
 * removePredicate: removePredicate,
 * updatePredicate: updatePredicate
 * }}
 */
function usePredicates() {
  return useContext(PredicatesContext);
}

export default usePredicates;
