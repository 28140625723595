// eslint-disable-next-line import/prefer-default-export
export const DATATABLE_EVENTS = {
  loadAllIds: 'datatable-load-all-ids',
  showEntitlementTeaser: 'showEntitlementTeaser',
  hideEntitlementTeaser: 'hideEntitlementTeaser',
};

export const DATATABLE_BATCH_ACTIONS = {
  delete: 'delete',
  moveToCampaigns: 'moveToCampaigns',
  addCollaborators: 'addCollaborators',
  duplicateToWorkspaces: 'duplicateToWorkspaces',
  batchDuplicateToCampaigns: 'batchDuplicateContentToCampaigns',
  askApproval: 'askApproval',
};
