import { useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { changeSegmentURL } from '@/segments/utils';
import { usePrevious, useEmitEvent } from '@/storychief/hooks';
import {
  changePredicatesURL,
  cleanPredicate,
  decodePredicates,
  getUnsavedPredicates,
  preparePredicates,
} from '@/predicates/utils';
import useModal from '@/modals/hooks/useModal';
import usePredicates from '@/predicates/hooks/usePredicates';
import SegmentsContext from '@/segments/context/Segments';
import DatatableFieldsContext from '@/datatables/context/DatatableFields';
import storeDatatableOptions from '@/datatables/utils/storeDatatableOptions';
import encodePredicates from '@/predicates/utils/encodePredicates';
import { PREDICATE_ANY_VALUE } from '@/predicates/constants/Constants';
import { ALL_SEGMENT } from '@/segments/constants/Constants';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import SEGMENT_TYPES from '@/segments/constants/segmentTypes';
import { DATATABLE_EVENTS } from '@/datatables/constants/Constants';

export default function useSegments() {
  // Hooks
  const history = useHistory();
  const location = useLocation();
  const saveModal = useModal('SaveSegmentModal');
  const { setPredicates, predefinedPredicates } = usePredicates();
  const {
    segments,
    segmentsType,
    activeSegment,
    setActiveSegment,
    activePredicates,
    setActivePredicates,
    isAllowSharing,
    isSegmentDirty,
    setIsSegmentDirty,
    isSupportUrl,
    isActive,
  } = useContext(SegmentsContext);
  const { datatableName } = useContext(DatatableFieldsContext) || {};
  const getEntitlement = useEntitlements();
  const emitShowEntitlementTeaser = useEmitEvent(DATATABLE_EVENTS.showEntitlementTeaser);
  const emitHideEntitlementTeaser = useEmitEvent(DATATABLE_EVENTS.hideEntitlementTeaser);

  // Variables
  const hasCustomSegments = segments.some((s) => s.id);
  const prevSegments = usePrevious(segments);

  // Functions
  const getSegmentPredicates = useCallback(
    (segment) => {
      const predicates = preparePredicates(decodePredicates(segment.predicates));

      if (predefinedPredicates) {
        return preparePredicates(predefinedPredicates).map((predicate) => {
          const segmentPredicate = predicates.find((p) => p.attribute === predicate.attribute);
          return segmentPredicate || predicate;
        });
      }

      return predicates;
    },
    [predefinedPredicates],
  );

  const handleSegmentChange = useCallback(
    (segment) => {
      if (
        segment.predicates !== activeSegment.predicates ||
        segment.name !== activeSegment.name ||
        !location.pathname.includes(segment.slug)
      ) {
        changeSegmentURL({
          location,
          history,
          segment,
          segmentType: segmentsType,
        });

        const predicates = getSegmentPredicates(segment);
        const validPredicates = predicates.filter((p) => p.valid !== false).map(cleanPredicate);

        setPredicates(predicates);
        setActivePredicates(validPredicates);
        setActiveSegment(segment);
        storeDatatableOptions(datatableName, {
          predicates: validPredicates.length > 0 ? encodePredicates(validPredicates) : null,
          segment: segment.slug,
        });
      }
    },
    [datatableName, activeSegment, location, history, getSegmentPredicates],
  );

  function getEntitlementName() {
    switch (segmentsType) {
      case SEGMENT_TYPES.postsets:
        return 'datatable-filtering-socials';
      case SEGMENT_TYPES.campaigns:
        return 'datatable-filtering-campaigns';
      case SEGMENT_TYPES.stories:
        return 'datatable-filtering-stories';
      case SEGMENT_TYPES.websitecontents:
      case SEGMENT_TYPES.webinars:
      case SEGMENT_TYPES.ebooks:
      case SEGMENT_TYPES.videoprojects:
      case SEGMENT_TYPES.newsletters:
      case SEGMENT_TYPES.podcasts:
        return 'datatable-filtering-content-types';
      default:
        return null;
    }
  }

  function isEntitledToFiltering() {
    const entitlement = getEntitlementName();

    if (entitlement) {
      return getEntitlement(entitlement);
    }

    return null;
  }

  function clearUnsavedPredicates() {
    const segmentPredicates = getSegmentPredicates(activeSegment);
    setActivePredicates(segmentPredicates);
    setPredicates(segmentPredicates);
  }

  const handlePredicatesChange = useCallback(
    (predicates) => {
      // Reset if the last predicate or last selected value (multi-select predicate) is removed.
      // This applies to all segments expect for the "all segment".
      const isEmpty = predicates.length === 0 && activeSegment.slug !== ALL_SEGMENT;
      const isUnselected =
        predicates.length > 0 &&
        predicates.every((p) => p.value === PREDICATE_ANY_VALUE) &&
        activeSegment.slug !== ALL_SEGMENT;

      if (isEmpty || isUnselected) {
        handleSegmentChange(segments.find((segment) => segment.slug === ALL_SEGMENT));
      } else {
        // Unsaved predicates are predicates that have been selected in the filter but are not a part
        // of a segment.
        const unsavedPredicates = getUnsavedPredicates({
          predicates,
          activeSegment,
        });
        const validPredicates = predicates.filter((p) => p.valid !== false);

        if (!isEntitledToFiltering()) {
          if (unsavedPredicates.length > 0) {
            emitShowEntitlementTeaser(getEntitlementName());
          } else {
            emitHideEntitlementTeaser();
          }
        }

        if (isSupportUrl) {
          changePredicatesURL({
            location,
            history,
            predicates: unsavedPredicates,
          });
        }

        storeDatatableOptions(datatableName, {
          predicates: unsavedPredicates.length > 0 ? encodePredicates(unsavedPredicates) : null,
        });

        if (JSON.stringify(validPredicates) !== JSON.stringify(activePredicates)) {
          setActivePredicates(validPredicates);
        } else if (!isSupportUrl && validPredicates.length === 0) {
          // When the URL is not supported we need to manually update the predicate to avoid stale data.
          setActivePredicates(validPredicates);
        }
        setIsSegmentDirty(unsavedPredicates.length > 0);
      }
    },
    [datatableName, activeSegment, location, history],
  );

  const handleSegmentSave = useCallback(() => {
    saveModal.toggle({
      props: {
        type: segmentsType,
        activeSegment,
        activePredicates,
        isExcludePredicateAllValue: predefinedPredicates?.length > 0,
      },
    });
  }, [segmentsType, activeSegment, activePredicates]);

  // Effects
  useEffect(() => {
    if (prevSegments && JSON.stringify(prevSegments) !== JSON.stringify(segments)) {
      if (prevSegments.length < segments.length) {
        // New segments was saved
        handleSegmentChange(segments[segments.length - 1]);
      } else {
        // Existing segment was updated
        const updatedSegment = segments.find((s) => s.id === activeSegment.id);
        handleSegmentChange(updatedSegment);

        // Update active segment data
        if (activeSegment.slug === updatedSegment.slug) {
          setActiveSegment(updatedSegment);
        }
      }
    }
  }, [segments]);

  // Return values
  return {
    segments,
    activeSegment,
    activePredicates,
    isAllowSharing,
    isSegmentDirty,
    isActive,
    hasCustomSegments,
    handleSegmentChange,
    handleSegmentSave,
    handlePredicatesChange,
    clearUnsavedPredicates,
  };
}
