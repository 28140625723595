import getDatatableOptionsKey from '@/datatables/utils/getDatatableOptionsKey';
import clientStorage from '@/storychief/utils/clientStorage';

/**
 * @param {string} datatableName
 * @returns {{predicates: null | Array, visibleFields: null | Array, segment: null | string}}
 */
export default function getDatatableOptions(datatableName) {
  const data = clientStorage.getItem(getDatatableOptionsKey(datatableName));
  return data ? JSON.parse(data) : { segment: null, predicates: null, visibleFields: null };
}
