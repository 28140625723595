import { createContext } from 'react';

const Segments = createContext({
  segments: [],
  segmentsType: null,
  activeSegment: null,
  setActiveSegment: () => {},
  activePredicates: [],
  setActivePredicates: () => {},
  isSegmentDirty: false,
  isSupportUrl: true,
  setIsSegmentDirty: () => {},
});

export default Segments;
