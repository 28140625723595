import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { Icon } from '@iconify-icon/react';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import DatatableHeaderSortIcon from '@/datatables/components/DatatableHeaderSortIcon';

const propTypes = {
  fieldDefinition: PropTypes.shape({
    key: PropTypes.string.isRequired,
    sortKey: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.node.isRequired,
    visible: PropTypes.bool,
    icon: PropTypes.string,
  }).isRequired,
  sortOrder: PropTypes.string,
  onSortChange: PropTypes.func,
};
const defaultProps = {
  onSortChange: null,
  sortOrder: null,
};

function DatatableHeader(props) {
  const { fieldDefinition, onSortChange, sortOrder } = props;

  function handleSortClick() {
    onSortChange(fieldDefinition.sortKey);
  }

  const classes = classNames(`datatable-header datatable-header--${fieldDefinition.key}`, {
    hidden: !fieldDefinition.visible,
  });

  const headerTextComponent =
    fieldDefinition.sortKey && onSortChange ? (
      <button
        className="datatable-header__text"
        type="button"
        tabIndex="0"
        onClick={handleSortClick}
      >
        {fieldDefinition.icon && !fieldDefinition.icon.includes(':') && (
          <em className={`small ${fieldDefinition.icon}`} key={`icon-${fieldDefinition.title}`} />
        )}
        {fieldDefinition.icon && fieldDefinition.icon.includes(':') && (
          <Icon icon={fieldDefinition.icon} inline width="14" height="14" className="mr-2" />
        )}
        {fieldDefinition.title}
        <DatatableHeaderSortIcon sortOrder={sortOrder} />
      </button>
    ) : (
      <span className="datatable-header__text">
        {fieldDefinition.icon && !fieldDefinition.icon.includes(':') && (
          <em className={`small ${fieldDefinition.icon}`} key={`icon-${fieldDefinition.title}`} />
        )}
        {fieldDefinition.icon && fieldDefinition.icon.includes(':') && (
          <Icon icon={fieldDefinition.icon} inline width="14" height="14" className="mr-2" />
        )}
        {fieldDefinition.title}
      </span>
    );

  return (
    <th className={classes}>
      <div className="d-block invisible">{headerTextComponent}</div>
      <ConditionalWrapper
        condition={!!fieldDefinition.description}
        wrapper={(children) => (
          <OverlayTrigger
            key="header-overlay"
            placement="top"
            overlay={
              <Tooltip id="header-tooltip">
                <div>{fieldDefinition.description}</div>
              </Tooltip>
            }
          >
            {children}
          </OverlayTrigger>
        )}
      >
        {headerTextComponent}
      </ConditionalWrapper>
    </th>
  );
}

DatatableHeader.propTypes = propTypes;
DatatableHeader.defaultProps = defaultProps;

export default DatatableHeader;
