import PropTypes from 'prop-types';

const propTypes = {
  sortOrder: PropTypes.oneOf(['ASC', 'DESC']),
};
const defaultProps = {
  sortOrder: null,
};

function DatatableHeaderSortIcon({ sortOrder }) {
  const className = `datatable-header-icon datatable-header-icon--${
    sortOrder ? sortOrder.toLowerCase() : 'none'
  }`;

  switch (sortOrder) {
    case 'ASC':
      return (
        <svg
          className={className}
          viewBox="0 0 11 11"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m 8.2,6.5929491 -2.6,-2.6 -2.6,2.6"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'DESC':
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
          <path
            d="M3.00117 3.86719L5.60117 6.46719L8.20117 3.86719"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          className={className}
          viewBox="0 0 6.4000001 10.05437"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 0.60000002,6.8543952 3.2,9.4543951 5.8,6.8543952"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 0.60000002,3.1999751 3.2,0.59997518 5.8,3.1999751"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}

DatatableHeaderSortIcon.propTypes = propTypes;
DatatableHeaderSortIcon.defaultProps = defaultProps;

export default DatatableHeaderSortIcon;
