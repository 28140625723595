import getDatatableOptions from '@/datatables/utils/getDatatableOptions';
import getDatatableOptionsKey from '@/datatables/utils/getDatatableOptionsKey';
import clientStorage from '@/storychief/utils/clientStorage';

/**
 * @param {string} datatableName
 * @param {object} newData
 */
export default function storeDatatableOptions(datatableName, newData) {
  if (datatableName) {
    const existingData = getDatatableOptions(datatableName) || {};

    clientStorage.setItem(
      getDatatableOptionsKey(datatableName),
      JSON.stringify({
        ...existingData,
        ...newData,
      }),
    );
  }
}
