import { createElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  fieldDefinition: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType]),
    accessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    defaultValue: PropTypes.string,
    visible: PropTypes.bool,
    componentProps: PropTypes.shape({}),
    handleOnClick: PropTypes.func,
    handleDuplicatePostset: PropTypes.func,
    refetch: PropTypes.func,
    currentPage: PropTypes.number,
    fontSize: PropTypes.oneOf(['regular', 'small']),
  }).isRequired,
  entry: PropTypes.shape().isRequired,
};

const defaultProps = {};

function DatatableCell(props) {
  const { entry, fieldDefinition } = props;
  const fontSize = fieldDefinition.fontSize ? fieldDefinition.fontSize : 'small';

  const handleOnClick = () => {
    if (fieldDefinition.handleOnClick) {
      return fieldDefinition.handleOnClick(entry.id);
    }
    return null;
  };

  const renderContent = () => {
    if (fieldDefinition.component) {
      return createElement(fieldDefinition.component, {
        entry,
        key: `datatable-cell-component-${entry.id}-${fieldDefinition.key}`,
        ...fieldDefinition.componentProps,
      });
    }
    if (fieldDefinition.accessor && typeof fieldDefinition.accessor === 'function') {
      return fieldDefinition.accessor(entry);
    }
    if (fieldDefinition.accessor && typeof fieldDefinition.accessor === 'string') {
      return entry[fieldDefinition.accessor];
    }
    return null;
  };

  const classes = classNames(`datatable-cell datatable-cell--${fieldDefinition.key} ${fontSize}`, {
    hidden: !fieldDefinition.visible,
    'cursor-pointer': fieldDefinition.handleOnClick,
  });

  const renderedContent = renderContent();

  return (
    <td className={classes} onClick={handleOnClick} aria-hidden="true">
      {renderedContent || fieldDefinition.defaultValue}
    </td>
  );
}

DatatableCell.propTypes = propTypes;
DatatableCell.defaultProps = defaultProps;

export default DatatableCell;
