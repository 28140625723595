import encodePredicates from '@/predicates/utils/encodePredicates';

export default function changePredicatesURL({ location, history, predicates }) {
  let newPath = location.pathname.split(':')[0];
  let encodedPredicates = null;

  if (predicates !== null && predicates.length > 0) {
    encodedPredicates = encodePredicates(predicates);
    newPath += `:${encodedPredicates}`;
  }

  // eslint-disable-next-line no-param-reassign
  location.pathname = newPath;
  history.push(location);
}
