import SEGMENT_TYPES from '@/segments/constants/segmentTypes';
import StoryChief from '@/storychief';

export default function changeSegmentURL({ location, history, segment, segmentType }) {
  let basePath = '';
  if (segmentType === SEGMENT_TYPES.campaigns) {
    basePath = `${StoryChief.dashboardBasePath}/campaigns`;
  } else {
    basePath = location.pathname.replace(/\/segments\/?(.*)$/i, '');
  }
  // eslint-disable-next-line no-param-reassign
  location.pathname = `${basePath}/segments/${segment.slug}`;
  history.push(location);
}
