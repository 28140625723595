import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLOR_NONE } from '@/storychief/constants/Constants';

const propTypes = {
  color: PropTypes.number.isRequired,
  isInteractive: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'table', 'calendar']),
};
const defaultProps = {
  isInteractive: false,
  variant: 'default',
};

function ColorIndicator({ color, isInteractive, variant }) {
  return (
    <div
      className={classNames(
        'color-coding',
        `color-coding--${variant}`,
        { 'color-coding--interactive': isInteractive },
        color !== COLOR_NONE ? `color-coding--${color}` : 'color-coding--none',
      )}
    >
      {isInteractive && (
        <span className="color-coding__icon">
          <em className="icon-eyedropper" />
        </span>
      )}
    </div>
  );
}

ColorIndicator.propTypes = propTypes;
ColorIndicator.defaultProps = defaultProps;

export default ColorIndicator;
