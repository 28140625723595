import cleanPredicate from '@/predicates/utils/cleanPredicate';
import decodePredicates from '@/predicates/utils/decodePredicates';
import { PREDICATE_ANY_VALUE } from '@/predicates/constants/Constants';

const isSet = ({ value }) => value !== PREDICATE_ANY_VALUE;

const isExposed = ({ attribute }) => attribute !== 'id';

const isValid = ({ valid }) => valid !== false;

/**
 * @param {object} data
 * @param {Array} data.predicates
 * @param {{ predicates: string }} data.activeSegment
 * @returns {Array}
 */
export default function getUnsavedPredicates({ predicates, activeSegment }) {
  const cleanedPredicates = predicates.map(cleanPredicate).filter(isSet).filter(isExposed);
  const cleanedSegmentPredicates = activeSegment
    ? decodePredicates(activeSegment.predicates).map(cleanPredicate)
    : [];

  if (JSON.stringify(cleanedSegmentPredicates) !== JSON.stringify(cleanedPredicates)) {
    return predicates.filter(isValid).filter(isSet).filter(isExposed).map(cleanPredicate);
  }

  return [];
}
