import PropTypes from 'prop-types';
import { some } from 'lodash-es';
import useDatatableSelection from '@/datatables/hooks/useDatatableSelection';
import Checkbox from '@/storychief/components/Checkbox';
import ColorIndicator from '@/storychief/components/ColorIndicator';

const propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  entry: PropTypes.shape({
    __typename: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,

  isDisabled: PropTypes.bool,
  color: PropTypes.number,
};
const defaultProps = {
  isDisabled: false,
  color: null,
};

function DatatableSelectionCell({ entries, entry, isDisabled, color }) {
  const { id, __typename } = entry;

  const { selection, toggleSelection } = useDatatableSelection();

  function getIsChecked() {
    return some(selection, __typename ? { id, __typename } : { id });
  }

  return (
    <td className="datatable-cell datatable-cell--selection">
      {color !== null && <ColorIndicator color={color} variant="table" />}
      <Checkbox
        isChecked={getIsChecked()}
        onChange={(e) => toggleSelection({ entries, entry, isSelectMultiple: e.shiftKey })}
        isDisabled={isDisabled}
      />
    </td>
  );
}

DatatableSelectionCell.propTypes = propTypes;
DatatableSelectionCell.defaultProps = defaultProps;

export default DatatableSelectionCell;
