import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import DatatableEntitlementTeaser from '@/entitlements/components/DatatableEntitlementTeaser';

const propTypes = {
  children: PropTypes.node.isRequired,
  entries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  verticalScroll: PropTypes.bool,
  isVerticalScrollSpacing: PropTypes.bool,
  noResult: PropTypes.node,
  loading: PropTypes.bool.isRequired,
};

const defaultProps = {
  verticalScroll: true,
  isVerticalScrollSpacing: true,
  noResult: <div>no result</div>,
};

function DatatableContainer({
  verticalScroll,
  entries,
  children,
  loading,
  noResult,
  isVerticalScrollSpacing,
}) {
  const wrapper = useCallback(
    (_children) => (
      <div
        className={classNames('vertical-scroll-container vertical-scroll-container--datatable', {
          'vertical-scroll-container--spacing':
            isVerticalScrollSpacing && (entries.length > 0 || loading),
        })}
      >
        <div className="vertical-scroll">{_children}</div>
      </div>
    ),
    [entries.length, loading],
  );

  return (
    <div className={`p-relative ${verticalScroll && 'd-flex flex-grow'}`}>
      <ConditionalWrapper condition={verticalScroll} wrapper={wrapper}>
        {!loading && !entries.length ? noResult : children}
      </ConditionalWrapper>
      {loading && !!entries.length && <div className="loading-overlay loading-overlay--absolute" />}
      <DatatableEntitlementTeaser />
    </div>
  );
}

DatatableContainer.propTypes = propTypes;
DatatableContainer.defaultProps = defaultProps;

export default DatatableContainer;
