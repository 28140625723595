import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useDatatableSelection from '@/datatables/hooks/useDatatableSelection';
import { useEmitEvent } from '@/storychief/hooks';
import { DATATABLE_EVENTS } from '@/datatables/constants/Constants';
import Checkbox from '@/storychief/components/Checkbox';

const propTypes = {
  total: PropTypes.number,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  isDisabled: PropTypes.bool,
};
const defaultProps = {
  total: 0,
  entries: [],
  isDisabled: false,
};

const SELECTION_TYPES = {
  default: 0,
  all: 1,
  allVisible: 2,
};

function DatatableSelectionHeader({ total, entries, isDisabled }) {
  // Hooks
  const { selection, setSelection, resetSelection } = useDatatableSelection();
  const emitLoadAllIds = useEmitEvent(DATATABLE_EVENTS.loadAllIds);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectionType, setSelectionType] = useState(SELECTION_TYPES.default);

  // Functions
  function isSelectionAll() {
    if (selectionType === SELECTION_TYPES.all) {
      return selection.length === total;
    }

    return selection.length === entries.length;
  }

  function handleOnDeselectAll() {
    resetSelection();
    setSelectionType(SELECTION_TYPES.default);
  }

  function handleOnSelectAllVisible() {
    setSelection(entries);
    setSelectionType(SELECTION_TYPES.allVisible);
  }

  function handleOnSelectAll() {
    setSelectionType(SELECTION_TYPES.all);

    if (total !== entries.length) {
      emitLoadAllIds();
    } else {
      setSelection(entries);
    }
  }

  function handleDropdownToggle() {
    if (!isDisabled) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  }

  function handleDropdownClose() {
    setIsDropdownOpen(false);
  }

  // Render
  return (
    <>
      {selection.length === 0 ? (
        <Dropdown
          id="datatable-selection"
          open={isDropdownOpen}
          onSelect={handleDropdownClose}
          onToggle={handleDropdownToggle}
        >
          <Dropdown.Toggle className="btn-chromeless btn-link m-0" noCaret>
            <div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="datatable-selection-deselect">Select all</Tooltip>}
              >
                <span>
                  <Checkbox isDisabled={isDisabled} />
                </span>
              </OverlayTrigger>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem eventKey="select-all-visible" onSelect={handleOnSelectAllVisible}>
              Select all visible ({entries.length})
            </MenuItem>
            <MenuItem eventKey="select-all" onSelect={handleOnSelectAll}>
              Select all ({total})
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="datatable-selection-deselect">Deselect all</Tooltip>}
        >
          <span>
            <Checkbox
              isChecked
              isDisabled={isDisabled}
              isIndeterminate={selection.length > 0 && !isSelectionAll()}
              onChange={handleOnDeselectAll}
            />
          </span>
        </OverlayTrigger>
      )}
    </>
  );
}

DatatableSelectionHeader.propTypes = propTypes;
DatatableSelectionHeader.defaultProps = defaultProps;

export default DatatableSelectionHeader;
