import { useEffect, useState, useMemo } from 'react';
import { findIndex, find } from 'lodash-es';
import PropTypes from 'prop-types';
import { ROLE_OWNER, ROLE_ADMIN } from '@/users/constants/Constants';
import DatatableFieldsContext from '@/datatables/context/DatatableFields';
import DatatableSelectionContext from '@/datatables/context/DatatableSelection';
import StoryChief from '@/storychief';
import storeDatatableOptions from '@/datatables/utils/storeDatatableOptions';
import getDatatableOptions from '@/datatables/utils/getDatatableOptions';

const propTypes = {
  name: PropTypes.string.isRequired,
  supportSelection: PropTypes.bool,
  children: PropTypes.node.isRequired,
  buildFunction: PropTypes.func,
  buildFunctionData: PropTypes.shape({}),
  defaultVisibleFields: PropTypes.arrayOf(PropTypes.string),
};
const defaultProps = {
  supportSelection: false,
  buildFunctionData: null,
  defaultVisibleFields: null,
  buildFunction: () => [],
};

function DatatableProvider(props) {
  const {
    name,
    supportSelection,
    buildFunction,
    buildFunctionData,
    children,
    defaultVisibleFields,
  } = props;

  // State
  const [fields, setFields] = useState([]);
  const [selectionAllSize, setSelectionAllSize] = useState(null);
  const [selection, setSelection] = useState([]);
  const [previousSelection, setPreviousSelection] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Variables
  const providerFieldsValue = useMemo(
    () => ({
      datatableName: name,
      fields,
      toggleFieldVisibility,
    }),
    [fields, selection],
  );
  const isSelectionAllowed = [ROLE_OWNER, ROLE_ADMIN].includes(StoryChief.user?.role);
  const providerSelectionValue = useMemo(
    () => ({
      supportSelection: supportSelection && isSelectionAllowed,
      selection,
      selectionAllSize,
      toggleSelection,
      resetSelection,
      setSelection,
      setSelectionAllSize,
    }),
    [fields, selection, selectionAllSize],
  );

  // Functions
  function resetSelection() {
    setSelectionAllSize(null);
    setSelection([]);
    setPreviousSelection(null);
  }

  function getNewSelectionIds(entry, entries) {
    const entryHasTypename = !!entry.__typename;

    const payloadPreviousIndex = entryHasTypename
      ? { id: previousSelection.id, __typename: previousSelection.__typename }
      : { id: previousSelection.id };
    const payloadIndex = entryHasTypename
      ? { id: entry.id, __typename: entry.__typename }
      : { id: entry.id };

    const previousIndex = findIndex(entries, payloadPreviousIndex);
    const index = findIndex(entries, payloadIndex);

    const newEntries =
      index > previousIndex
        ? entries.slice(previousIndex, index + 1)
        : entries.slice(index, previousIndex + 1);

    return newEntries;
  }

  function removeSelection({ entry, entries, isSelectMultiple = false }) {
    if (isSelectMultiple && previousSelection) {
      setSelection(selection.filter((s) => !getNewSelectionIds(entry, entries).includes(s)));
    } else {
      setSelection(selection.filter((s) => s.id !== entry.id));
    }
  }

  function addSelection({ entry, entries, isSelectMultiple }) {
    if (isSelectMultiple && previousSelection) {
      const newSelection = Array.from(
        new Set([...selection, ...getNewSelectionIds(entry, entries)]),
      ); // Use Set to remove duplicates.
      setSelection(newSelection);
    } else {
      setSelection([...selection, entry]);
    }
  }

  function toggleSelection({ entry, entries, isSelectMultiple = false }) {
    const isAlreadySelected = find(
      selection,
      entry.__typename ? { id: entry.id, __typename: entry.__typename } : { id: entry.id },
    );

    if (isAlreadySelected) {
      removeSelection({ entry, entries, isSelectMultiple });
    } else {
      addSelection({ entry, entries, isSelectMultiple });
    }

    setPreviousSelection(entry);
  }

  function getDefaultVisibleFields(newFields) {
    return (
      defaultVisibleFields ||
      getDatatableOptions(name).visibleFields ||
      newFields.filter((f) => f.visible).map((f) => f.key)
    );
  }

  function toggleFieldVisibility(fieldKey) {
    const newFields = fields
      .slice()
      .map((field) => (field.key === fieldKey ? { ...field, visible: !field.visible } : field));
    const visibleFields = newFields.filter((f) => f.visible);

    storeDatatableOptions(name, { visibleFields: visibleFields.map((f) => f.key) });
    setFields(newFields);
  }

  function initDatatableFields(newFields) {
    const newVisibleFields = getDefaultVisibleFields(newFields);

    setFields(
      newFields.map((f) => ({
        ...f,
        visible: newVisibleFields.includes(f.key),
      })),
    );
  }

  function initDatatable() {
    initDatatableFields(buildFunction(buildFunctionData));
    setIsInitialized(true);
  }

  useEffect(() => {
    initDatatable();
  }, [buildFunctionData]);

  if (!isInitialized) {
    return null;
  }

  // Render
  return (
    <DatatableFieldsContext.Provider value={providerFieldsValue}>
      <DatatableSelectionContext.Provider value={providerSelectionValue}>
        {children}
      </DatatableSelectionContext.Provider>
    </DatatableFieldsContext.Provider>
  );
}

DatatableProvider.propTypes = propTypes;
DatatableProvider.defaultProps = defaultProps;

export default DatatableProvider;
