import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useDatatableFields from '@/datatables/hooks/useDatatableFields';
import DatatableRender from '@/datatables/components/DatatableRender';
import useDatatableSelection from '@/datatables/hooks/useDatatableSelection';

const propTypes = {
  sort: PropTypes.shape({
    column: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  }),
  loading: PropTypes.bool,
  entries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showHeaders: PropTypes.bool,
  onSortChange: PropTypes.func,
  className: PropTypes.string,
  total: PropTypes.number,
  isShowColorCoding: PropTypes.bool,
};

const defaultProps = {
  onSortChange: null,
  showHeaders: true,
  className: '',
  sort: null,
  loading: false,
  total: 0,
  isShowColorCoding: false,
};

function Datatable(props) {
  const { entries, loading, ...childProps } = props;

  // Hooks
  const { fields } = useDatatableFields();
  const { supportSelection } = useDatatableSelection();

  // Variables
  const enabledFields = useMemo(() => fields.filter((field) => field.visible !== false), [fields]);

  // Render
  return (
    <DatatableRender
      {...childProps}
      entries={entries}
      isShowPlaceholderLoading={entries.length === 0 && loading}
      isLoading={loading}
      fields={enabledFields}
      supportSelection={supportSelection}
    />
  );
}

Datatable.propTypes = propTypes;
Datatable.defaultProps = defaultProps;

export default Datatable;
