import { memo } from 'react';
import PropTypes from 'prop-types';
import DatatableHeader from '@/datatables/components/DatatableHeader';
import DatatableCell from '@/datatables/components/DatatableCell';
import DatatableSelectionHeader from '@/datatables/components/DatatableSelectionHeader';
import DatatableSelectionCell from '@/datatables/components/DatatableSelectionCell';

const propTypes = {
  sort: PropTypes.shape({
    column: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  }),
  isShowPlaceholderLoading: PropTypes.bool,
  entries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showHeaders: PropTypes.bool,
  onSortChange: PropTypes.func,
  className: PropTypes.string,
  total: PropTypes.number,
  supportSelection: PropTypes.bool,
  isSelectionDisabled: PropTypes.bool,
  isShowColorCoding: PropTypes.bool,
};

const defaultProps = {
  onSortChange: null,
  showHeaders: true,
  className: '',
  sort: null,
  isShowPlaceholderLoading: false,
  total: 0,
  supportSelection: false,
  isSelectionDisabled: false,
  isShowColorCoding: false,
};

const PLACEHOLDER_ITEMS = ['1', '2', '3', '4'].map((id) => ({
  id: `column-loading-${id}-selection`,
}));

function DatatableRender({
  entries,
  isShowPlaceholderLoading,
  fields,
  onSortChange,
  showHeaders,
  sort,
  className,
  total,
  supportSelection,
  isSelectionDisabled,
  isShowColorCoding,
}) {
  return (
    <table className={`datatable datatable-table table ${className}`}>
      {showHeaders && (
        <thead>
          <tr>
            {supportSelection && (
              <th className="datatable-header datatable-header--selection">
                <span className="datatable-header__text">
                  <DatatableSelectionHeader
                    total={total}
                    entries={entries}
                    isDisabled={isSelectionDisabled}
                  />
                </span>
              </th>
            )}
            {fields.map((field) => (
              <DatatableHeader
                key={field.key}
                fieldDefinition={field}
                onSortChange={onSortChange}
                sortOrder={field.key === sort?.column ? sort.order : null}
              />
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {isShowPlaceholderLoading &&
          PLACEHOLDER_ITEMS.map((item) => (
            <tr key={`row-loading-${item.id}`}>
              {supportSelection && (
                <DatatableSelectionCell entries={PLACEHOLDER_ITEMS} entry={item} isDisabled />
              )}
              {fields.map((field) => (
                <td key={`column-loading-${item.id}-${field.key}`}>
                  <div className="placeholder-animated-2">
                    <div className="ctrl ctrl-80" />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        {entries.map((entry) => (
          <tr key={`datable-row-${entry.id}`}>
            {supportSelection && (
              <DatatableSelectionCell
                entry={entry}
                entries={entries}
                isDisabled={isSelectionDisabled}
                color={isShowColorCoding ? entry.color : undefined}
              />
            )}
            {fields.map((field) => (
              <DatatableCell
                fieldDefinition={field}
                entry={entry}
                key={`datable-cell-${entry.id}-${field.key}`}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

DatatableRender.propTypes = propTypes;
DatatableRender.defaultProps = defaultProps;

export default memo(DatatableRender);
