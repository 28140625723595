import { useState } from 'react';
import InlineOverlayEntitlementTeaser from '@/entitlements/components/InlineOverlayEntitlementTeaser';
import { useEmitEventEffect } from '@/storychief/hooks';
import { DATATABLE_EVENTS } from '@/datatables/constants/Constants';
import useSegments from '@/segments/hooks/useSegments';

const propTypes = {};
const defaultProps = {};

function DatatableEntitlementTeaser() {
  const [entitlement, setEntitlement] = useState(null);
  const { clearUnsavedPredicates } = useSegments();
  const isShow = entitlement !== null;

  function handleOnClose() {
    setEntitlement(null);
    clearUnsavedPredicates();
  }

  useEmitEventEffect((newEntitlement) => {
    if (!isShow) {
      setEntitlement(newEntitlement);
    }
  }, DATATABLE_EVENTS.showEntitlementTeaser);

  useEmitEventEffect(() => {
    if (isShow) {
      handleOnClose();
    }
  }, DATATABLE_EVENTS.hideEntitlementTeaser);

  if (!entitlement) {
    return null;
  }

  return (
    <InlineOverlayEntitlementTeaser
      entitlement={entitlement}
      isShow={isShow}
      isClosable
      onClose={handleOnClose}
      variant="datatable"
    />
  );
}

DatatableEntitlementTeaser.propTypes = propTypes;
DatatableEntitlementTeaser.defaultProps = defaultProps;

export default DatatableEntitlementTeaser;
